import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Authentication services
import { AuthenticationService } from './services/authentication.service';
import { TokenInterceptor } from './services/token.interceptor';

import { RootPathGuard } from './routing/root-path.guard';
import { AuthenticatedGuard } from './routing/authenticated.guard';
import { ClientConfiguration } from '../model/client-configuraton';


@NgModule({
   imports: [
      CommonModule
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: TokenInterceptor,
         deps: [AuthenticationService],
         multi: true
      },
      AuthenticationService,
      AuthenticatedGuard,
      RootPathGuard
   ]
})
export class AuthenticationModule {

   public static initializeAsync(clientConfiguration: ClientConfiguration, authenticationService: AuthenticationService): Promise<void> {
      return authenticationService.initializeAsync(clientConfiguration);
   }
}
