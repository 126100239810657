<div class="asc-area-side-panel asc-menu">
   <div class="asc-menu-banner">
      <img src="/assets/images/ari-logo.svg" alt="asc-logo" />
   </div>
   <mat-nav-list class="fx-flex">
      <mat-list-item (click)="navigateTo(homeEntryPoint)">
         <mat-icon matListItemIcon class="material-symbols-rounded-filled">home</mat-icon>
         <span matListItemLine>Startseite</span>
      </mat-list-item>

      <mat-divider></mat-divider>

      <div *ngFor="let featureNavigationItem of featureNavigationItems">
         <div *ngIf="featureNavigationItem.entryPoints.length > 1; then multiEntryPoints else singleEntryPoint"></div>
         <ng-template #multiEntryPoints>
            <mat-list-item [matMenuTriggerFor]="menu">
               <asc-module-icon matListItemIcon [primary]="featureNavigationItem.icons.primary"
                  [secondary]="featureNavigationItem.icons.secondary"></asc-module-icon>
               <span matListItemLine [innerHTML]="featureNavigationItem.displayNameHtml || featureNavigationItem.displayName"></span>
            </mat-list-item>
            <mat-menu #menu="matMenu" [overlapTrigger]="true">
               <button mat-menu-item [ngClass]="{'asc-new-section': entryPoint.newSection}"
                  (click)="navigateTo(entryPoint)"
                  [disabled]="(entryPoint.isEnabled) ? !entryPoint.isEnabled() : false"
                  *ngFor="let entryPoint of featureNavigationItem.entryPoints">
                  <asc-module-icon [primary]="entryPoint.icons?.primary" [secondary]="entryPoint.icons?.secondary">
                  </asc-module-icon>
                  {{entryPoint.displayName}}
               </button>
            </mat-menu>
         </ng-template>
         <ng-template #singleEntryPoint>
            <mat-list-item (click)="navigateTo(featureNavigationItem.entryPoints[0])"
               [disabled]="(featureNavigationItem.entryPoints[0].isEnabled) ? !featureNavigationItem.entryPoints[0].isEnabled() : false">
               <asc-module-icon matListItemIcon [primary]="featureNavigationItem.icons.primary"
                  [secondary]="featureNavigationItem.icons.secondary"></asc-module-icon>
               <span matListItemLine [innerHTML]="featureNavigationItem.displayNameHtml || featureNavigationItem.displayName"></span>
            </mat-list-item>
         </ng-template>
      </div>
   </mat-nav-list>

   <mat-divider *ngIf="settingsNavigationItems.length > 0"></mat-divider>

   <mat-nav-list *ngIf="settingsNavigationItems.length > 0">
      <mat-list-item (click)="navigateTo(settingsEntryPoint)">
         <mat-icon matListItemIcon class="material-symbols-rounded-filled">settings</mat-icon>
         <span matListItemLine>Einstellungen</span>
      </mat-list-item>
   </mat-nav-list>

   <div class="asc-footer mat-elevation-z2">
      <p>ARI - ASC {{version}}</p>
   </div>
</div>