import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { from, lastValueFrom, Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

   constructor(
      private authenticationService: AuthenticationService,
   ) { }


   public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (request.withCredentials ?? false) {
         // Add authorization
         return from(this.addAccessTokenAsync(request, next));
      }
      else {
         // No authorization required
         return next.handle(request);
      }
   }

   private async addAccessTokenAsync(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
      const accessToken = await this.authenticationService.getAccessTokenAsync();
      if (accessToken?.isValid || false) {
         const authenticatedRequest = this.addHeaderAuthorization(request, accessToken.token || '');
         return lastValueFrom(next.handle(authenticatedRequest));
      }
      else {
         // Session timed-out
         await this.authenticationService.loginAsync();

         throw new HttpErrorResponse({
            error: 'Session timeout',
            status: 401,
            statusText: 'Unauthorized',
            url: request.url
         });
      }
   }

   private addHeaderAuthorization(request: HttpRequest<any>, token: string): HttpRequest<any> {
      const headers: { [name: string]: string | string[]; } = {};
      if (token) {
         headers['Authorization'] = `Bearer ${token}`;
      }

      return request.clone({ setHeaders: headers, withCredentials: false });
   }
}
