import { Injectable } from "@angular/core";
import { FormViewModel } from "../view-model/form-view-model";
import { FormNavigationService } from "./form-navigation.service";

@Injectable()
export class FormService {
   private formsMap = new Map<FormViewModel, FormNavigationService>();

   public getNavigation(form: FormViewModel): FormNavigationService {
      if (!this.formsMap.has(form)) {
         this.formsMap.set(form, new FormNavigationService(form));
      }

      return this.formsMap.get(form)!;
   }

   public dropForm(form: FormViewModel) {
      this.formsMap.delete(form);
   }
}