import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FormFieldBoolDropdownComponent } from './components/form-field-bool-dropdown/form-field-bool-dropdown.component';
import { FormFieldBoolCheckBoxComponent } from './components/form-field-bool-check-box/form-field-bool-check-box.component';
import { FormFieldBoolSlideToggleComponent } from './components/form-field-bool-slide-toggle/form-field-bool-slide-toggle.component';
import { FormFieldDateTimeDatepickerComponent } from './components/form-field-datetime-datepicker/form-field-datetime-datepicker.component';
import { FormFieldRadioButtonsComponent } from './components/form-field-radio-buttons/form-field-radio-buttons.component';
import { FormFieldStringTextBoxComponent } from './components/form-field-string-text-box/form-field-string-text-box.component';
import { FormFieldStringTextBoxMultilineComponent } from './components/form-field-string-text-box-multiline/form-field-string-text-box-multiline.component';
import { FormFieldValueItemDropdownComponent } from './components/form-field-valueitem-dropdown/form-field-valueitem-dropdown.component';
import { FormFieldValueItemDropdownMultiSelectionComponent } from './components/form-field-valueitem-dropdown-multi-selection/valueitem-dropdown-multi-selection.component';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { FormSectionComponent } from './components/form-section/form-section.component';
import { FormSectionNavigationComponent } from './components/form-section-navigation/form-section-navigation.component';
import { FormService } from './services/form.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormFieldTooltipComponent } from './components/form-field-tooltip/form-field-tooltip.component';
import { FormIconComponent } from './components/form-icon/form-icon.component';


@NgModule({
   declarations: [
      FormSectionComponent,
      FormSectionNavigationComponent,
      FormGroupComponent,
      FormFieldBoolCheckBoxComponent,
      FormFieldBoolDropdownComponent,
      FormFieldBoolSlideToggleComponent,
      FormFieldDateTimeDatepickerComponent,
      FormFieldTooltipComponent,
      FormFieldStringTextBoxComponent,
      FormFieldStringTextBoxMultilineComponent,
      FormFieldValueItemDropdownComponent,
      FormFieldValueItemDropdownMultiSelectionComponent,

      FormFieldRadioButtonsComponent
   ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      MatAutocompleteModule,
      MatCheckboxModule,
      MatChipsModule,
      MatDatepickerModule,
      MatIconModule,
      MatFormFieldModule,
      MatInputModule,
      MatRadioModule,
      MatSelectModule,
      MatSlideToggleModule,
      MatTooltipModule,

      FormIconComponent
   ],
   exports: [
      FormSectionComponent,
      FormSectionNavigationComponent
   ],
   providers: [
      provideMomentDateAdapter(),
      FormService
   ]
})
export class FormModule {
}
