import { LOCALE_ID, NgModule, InjectionToken, inject, provideAppInitializer } from '@angular/core';
import { AsyncPipe, registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDeCH from '@angular/common/locales/de-CH';
import { RouterModule } from '@angular/router';
import moment from 'moment';
import 'moment/locale/de-ch';

import { SharedModule } from './shared.module';

// UI modules
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MenuComponent } from './components/menu/menu.component';
import { SettingsMenuComponent } from './components/settings-menu/settings-menu.component';

import { DashboardComponent } from './views/dashboard/dashboard.component';

import { AuthenticationModule } from './authentication/authentication.module';

// Services
import { BusyService } from './services/busy.service';
import { CoreService } from './services/core.service';
import { DesktopService } from './services/desktop.service';
import { ModuleManagerService } from './services/module-manager.service';
import { SessionService } from './services/session.service';
import { SettingsComponent } from './views/settings/settings.component';
import { CORE_ROUTER_MODULE } from './core-router.module';
import { AscModuleIconModule } from './components/module-icon/module-icon.module';
import { HttpRequestCachingInterceptor } from './services/http-request-caching.interceptor';
import { DataSourceAvailabilityInterceptor } from './services/data-source-availability.interceptor';
import { HttpRequestCachingService } from './services/http-request-caching.service';
import { AscNotificationBarComponent } from './components/notification-bar/notification-bar.component';
import { NotificationBarService } from './services/notification-bar.service';
import { ClientConfiguration } from './model/client-configuraton';
import { FunctionClient } from './azure-functions';
import { NavigationService } from './services/navigation.service';
import { TelemetryService } from './services/telemetry.service';
import { AuthenticationService } from './authentication/services/authentication.service';

export const CLIENT_CONFIG = new InjectionToken<ClientConfiguration>('client.config');


@NgModule({
    declarations: [
        MenuComponent,
        SettingsComponent,
        SettingsMenuComponent,
        DashboardComponent,
        AscNotificationBarComponent
    ],
    exports: [
        SharedModule,
        RouterModule,
        AscNotificationBarComponent,
        MenuComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatTooltipModule,
        AscModuleIconModule,
        AuthenticationModule,
        CORE_ROUTER_MODULE
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de-CH' },

        /* MomentDateAdapter */
        { provide: MAT_DATE_LOCALE, useValue: 'de' },
        provideMomentDateAdapter(undefined, { useUtc: true, strict: true }),

        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestCachingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DataSourceAvailabilityInterceptor, multi: true },
        provideAppInitializer(() => {
            const initializerFn = ((httpClient: HttpClient, telemetryService: TelemetryService, authenticationService: AuthenticationService, coreService: CoreService, moduleManagerService: ModuleManagerService, sessionService: SessionService) => {
                // Initialize function-controllers
                FunctionClient.httpClient = httpClient;
                // Initialize client configuration
                return async () => {
                    // Load client configuration
                    await coreService.loadClientConfigurationAsync();
                    const clientConfiguration = coreService.clientConfiguration;

                    // Initialize Application Insights
                    telemetryService.config = {
                        instrumentationKey: clientConfiguration.telemetry.applicationInsightsInstrumentationKey
                    };
                    telemetryService.init();

                    // Initialize authentication module
                    await AuthenticationModule.initializeAsync(clientConfiguration, authenticationService);

                    // Initialize core module
                    moduleManagerService.initialize(clientConfiguration);
                    sessionService.initialize(clientConfiguration);

                    // Handle sign-in
                    let isSignedIn = await authenticationService.loginAfterRedirectAsync();
                    if (!isSignedIn) {
                        // Silient sign-in
                        isSignedIn = await authenticationService.loginSSOAsync(true);
                    }
                };
            })(inject(HttpClient), inject(TelemetryService), inject(AuthenticationService), inject(CoreService), inject(ModuleManagerService), inject(SessionService));
            return initializerFn();
        }),
        HttpRequestCachingService,
        AsyncPipe,
        BusyService,
        CoreService,
        DesktopService,
        ModuleManagerService,
        NavigationService,
        NotificationBarService,
        SessionService,
        TelemetryService,
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class CoreModule {
    constructor() {
        registerLocaleData(localeDeCH);
        moment.locale('de-ch');
    }
}
